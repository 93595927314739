<template>
	<div>
		<auto-withdrawal-limit-filters
			:auto-type-filter.sync="autoTypeFilter"
			:auto-type-options="autoTypeOptions"
			:currency-filter.sync="currencyFilter"
			:currency-options="currencyOptions"
			@refetch-data="refetchData"
		/>
		<b-card
			no-body
		>
			<!-- <div
				v-if="$can('create','paymenttype')"
				class="m-2"
			>
				<b-row>
					<b-col
						cols="12"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddAutoWithdrawalLimitModal"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> New
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div> -->
			<b-table
				ref="refAutoWithdrawalLimitTable"
				class="position-relative table-white-space mb-0"
				:items="fetchAutoWithdrawalLimitLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				show-empty
			>
				<template #empty="scope">
					<div
						class="text-center p-1"
						style="opacity: 0.3"
					>
						<feather-icon
							icon="ArchiveIcon"
							size="36"
						/></br>
						<span class="">No Data</span>
					</div>
				</template>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(limit)="data">
          <b-link
              class="text-primary cursor-pointer"
              @click="showEditAutoWithdrawalLimitModal({
							auto_withdrawal_id: data.item.id,
							limit: data.item.limit,
							auto_type: data.item.auto_type,
							currency: data.item.currency,
							created_at: data.item.created_at,
							updated_at: data.item.updated_at,
						})"
          >
            {{ parseFloat(data.item.limit) }}
          </b-link>
				</template>

				<template #cell(auto_type)="data">
          {{ data.item.auto_type.name }}
        </template>

				<template #cell(currency)="data">
					{{ data.item.currency }}
				</template>

				<template #cell(created_at)="data">
					{{ formatDateTime(data.item.created_at ? data.item.created_at : '').replace('.000000Z', '') }}
				</template>

				<template #cell(updated_at)="data">
					{{ formatDateTime(data.item.updated_at ? data.item.updated_at : '').replace('.000000Z', '') }}
				</template>

				<template #cell(action)="data">
					<b-link
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
						@click="showEditAutoWithdrawalLimitModal({
							auto_withdrawal_id: data.item.id,
							limit: data.item.limit,
							auto_type: data.item.auto_type,
							currency: data.item.currency,
							created_at: data.item.created_at,
							updated_at: data.item.updated_at,
						})"
					>
						<feather-icon icon="Edit3Icon" size="16"/>
					</b-link>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalAutoWithdrawalLimit"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Modal Add -->
		<add-auto-withdrawal-limit-modal
			:auto-type-options="autoTypeOptions"
			:currency-options="currencyOptions"
			@refetch-data="refetchData"
			v-if="$can('create', 'autowithdrawallimit')"
		/>
		<!-- Modal Edit -->
		<edit-auto-withdrawal-limit-modal
			:auto-withdrawal-limit-detail="autoWithdrawalLimitDetail"
			:auto-type-options="autoTypeOptions"
			:currency-options="currencyOptions"
			@refetch-data="refetchData"
			/>
			<!-- v-if="$can('create', 'autowithdrawallimitedit')" -->
	</div>
</template>

<script>
import {
  BButton, BModal, VBModal,
  BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { numberFormat, formatDateTime, resolveCurrency } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import AutoWithdrawalLimitFilters from './AutoWithdrawalLimitFilters.vue'
import autoWithdrawalStoreModule from './autoWithdrawalStoreModule'
import currencyStoreModule from '@/views/local-setting/local/currencyStoreModule'
import i18n from '@/libs/i18n'

export default {
	components: {
		AutoWithdrawalLimitFilters,
		AddAutoWithdrawalLimitModal: () => import('./AddAutoWithdrawalLimitModal.vue'),
		editAutoWithdrawalLimitModal: () => import('./EditAutoWithdrawalLimitModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
	},
	directives: {
    'b-tooltip':VBTooltip,
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			autoWithdrawalLimitDetail: null,
		}
	},
	methods: {
		showAddAutoWithdrawalLimitModal() {
			this.$bvModal.show('modal-add-auto-withdrawal-limit')
		},
		showEditAutoWithdrawalLimitModal(datas) {
			this.autoWithdrawalLimitDetail = datas
			this.$bvModal.show('modal-edit-auto-withdrawal-limit')
		},
	},
	setup() {
		if (!store.hasModule('local-setting-currency')) store.registerModule('local-setting-currency', currencyStoreModule)
		if (!store.hasModule('payment-auto-withdrawal')) store.registerModule('payment-auto-withdrawal', autoWithdrawalStoreModule)
		onUnmounted(() => {
			if (store.hasModule('payment-auto-withdrawal')) store.unregisterModule('payment-auto-withdrawal')
		})
		const toast = useToast()
		const perPage = ref(25)
		const totalAutoWithdrawalLimit = ref(0)
		const currentPage = ref(1)
		const autoTypeFilter = ref(null)
		const currencyFilter = ref(null)
		const refAutoWithdrawalLimitTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'limit', label: i18n.t('Limit') },
			{ key: 'auto_type', label: i18n.t('Type') },
			{ key: 'currency', label: i18n.t('Currency') },
			{ key: 'created_by', label: i18n.t('Created by') },
			{ key: 'updated_by', label: i18n.t('Updated by') },
			{ key: 'created_at', label: i18n.t('Created at') },
			{ key: 'updated_at', label: i18n.t('Updated at') },
			{ key: 'action', label: i18n.t('Actions') },
		]

		const refetchData = () => {
			refAutoWithdrawalLimitTable.value.refresh()
		}

		watch([currentPage, autoTypeFilter, currencyFilter], () => {
			refetchData()
		})

		const fetchAutoWithdrawalLimitLists = (ctx, callback) => {
			store
				.dispatch('payment-auto-withdrawal/fetchAutoWithdrawals', {
					page: currentPage.value,
					currency: currencyFilter.value,
					auto_type: autoTypeFilter.value,
				})
				.then(response => {
					if (response.data.code === '00') {
						callback(response.data.data)
						totalAutoWithdrawalLimit.value = response.data.count
						perPage.value = currentPage.value === 1 ? response.data.data.length : 25
					} else {
						toast({
							component: ToastificationContent,
							props: {
								title: response.data.message,
								icon: 'AlertTriangleIcon',
								variant: 'danger',
							},
						})
					}
				})
				.catch(error => {
					const { response } = error
					toast({
						component: ToastificationContent,
						props: {
							title: response.data.message || 'Error fetching Auto Withdrawal list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refAutoWithdrawalLimitTable.value ? refAutoWithdrawalLimitTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalAutoWithdrawalLimit.value,
			}
		})

		const autoTypeOptions = ref([])
		const currencyOptions = ref([])

		store.dispatch('local-setting-currency/fetchCurrencies')
			.then(response => {
				if (response.data.code == '00') {
					currencyOptions.value = response.data.data
				}
			})

		store
			.dispatch('payment-auto-withdrawal/fetchAutoTypes')
			.then(response => {
				if (response.data.code === '00') {
					autoTypeOptions.value = response.data.data
				} else {
					toast({
						component: ToastificationContent,
						props: {
							title: response.data.message,
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				}
			})

		return {
			fetchAutoWithdrawalLimitLists,
			tableColumns,
			perPage,
			currentPage,
			totalAutoWithdrawalLimit,
			dataMeta,
			refAutoWithdrawalLimitTable,

			refetchData,

			autoTypeFilter,
			currencyFilter,

			formatDateTime,
			numberFormat,
			resolveCurrency,
			autoTypeOptions,
			currencyOptions,
		}
	},
}
</script>