/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class AutoWithdrawalService {

	getAutoWithdrawalLists(queryParams) {
		const pars = {
			currency: queryParams.currency,
			auto_type: queryParams.auto_type,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/auto-withdrawal/index', { headers: authHeader(), params: pars })
	}

	getAutoTypes() {
		return instance.get('/api/auto-withdrawal/auto-type/index', { headers: authHeader() })
	}

    addAutoWithdrawal(autoWithdrawalData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('limit', autoWithdrawalData.limit)
		data.append('currency', autoWithdrawalData.currency)
		data.append('auto_type', autoWithdrawalData.auto_type)

		return instance.post('/api/auto-withdrawal/create', data, { headers: authHeader() })
    }

    editAutoWithdrawal(autoWithdrawalData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('auto_withdrawal_id', autoWithdrawalData.auto_withdrawal_id)
		data.append('limit', autoWithdrawalData.limit)
		data.append('currency', autoWithdrawalData.currency)

		return instance.post('/api/auto-withdrawal/edit', data, { headers: authHeader() })
    }
}

export default new AutoWithdrawalService()
