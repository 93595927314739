var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('auto-withdrawal-limit-filters',{attrs:{"auto-type-filter":_vm.autoTypeFilter,"auto-type-options":_vm.autoTypeOptions,"currency-filter":_vm.currencyFilter,"currency-options":_vm.currencyOptions},on:{"update:autoTypeFilter":function($event){_vm.autoTypeFilter=$event},"update:auto-type-filter":function($event){_vm.autoTypeFilter=$event},"update:currencyFilter":function($event){_vm.currencyFilter=$event},"update:currency-filter":function($event){_vm.currencyFilter=$event},"refetch-data":_vm.refetchData}}),_c('b-card',{attrs:{"no-body":""}},[_c('b-table',{ref:"refAutoWithdrawalLimitTable",staticClass:"position-relative table-white-space mb-0",attrs:{"items":_vm.fetchAutoWithdrawalLimitLists,"responsive":"","fields":_vm.tableColumns,"primary-key":"index","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('div',{staticClass:"text-center p-1",staticStyle:{"opacity":"0.3"}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon","size":"36"}}),_c('br'),_c('span',{},[_vm._v("No Data")])],1)]}},{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.perPage * (_vm.currentPage - 1) + data.index + 1)+" ")]}},{key:"cell(limit)",fn:function(data){return [_c('b-link',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.showEditAutoWithdrawalLimitModal({
							auto_withdrawal_id: data.item.id,
							limit: data.item.limit,
							auto_type: data.item.auto_type,
							currency: data.item.currency,
							created_at: data.item.created_at,
							updated_at: data.item.updated_at,
						})}}},[_vm._v(" "+_vm._s(parseFloat(data.item.limit))+" ")])]}},{key:"cell(auto_type)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.auto_type.name)+" ")]}},{key:"cell(currency)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.currency)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDateTime(data.item.created_at ? data.item.created_at : '').replace('.000000Z', ''))+" ")]}},{key:"cell(updated_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDateTime(data.item.updated_at ? data.item.updated_at : '').replace('.000000Z', ''))+" ")]}},{key:"cell(action)",fn:function(data){return [_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-info",modifiers:{"hover":true,"v-info":true}}],attrs:{"title":_vm.$t('Edit')},on:{"click":function($event){return _vm.showEditAutoWithdrawalLimitModal({
							auto_withdrawal_id: data.item.id,
							limit: data.item.limit,
							auto_type: data.item.auto_type,
							currency: data.item.currency,
							created_at: data.item.created_at,
							updated_at: data.item.updated_at,
						})}}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon","size":"16"}})],1)]}}])}),_c('div',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalAutoWithdrawalLimit,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),(_vm.$can('create', 'autowithdrawallimit'))?_c('add-auto-withdrawal-limit-modal',{attrs:{"auto-type-options":_vm.autoTypeOptions,"currency-options":_vm.currencyOptions},on:{"refetch-data":_vm.refetchData}}):_vm._e(),_c('edit-auto-withdrawal-limit-modal',{attrs:{"auto-withdrawal-limit-detail":_vm.autoWithdrawalLimitDetail,"auto-type-options":_vm.autoTypeOptions,"currency-options":_vm.currencyOptions},on:{"refetch-data":_vm.refetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }