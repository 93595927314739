<template>
	<b-card no-body>
		<b-card-body>
			<b-row>
				<!-- Auto Type -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Type')}}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="autoTypeFilter"
						:options="autoTypeOptions"
						class="w-100"
						:reduce="val => val.id"
						label="name"
						@input="(val) => $emit('update:autoTypeFilter', val)"
					/>
				</b-col>
				<!-- Currency -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Currency') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="currencyFilter"
						:options="currencyOptions"
						class="w-100"
						:reduce="val => val.value"
						label="value"
						@input="(val) => $emit('update:currencyFilter', val)"
					/>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
				>
					<b-button
						variant="outline-secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormInput, BCard, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BFormInput,
		BCardBody,
		vSelect,
	},
	props: {
		autoTypeFilter: {
			type: [Number, null],
			default: null,
		},
		currencyFilter: {
			type: [String, null],
			default: null,
		},
		autoTypeOptions: {
			type: Array,
			required: true,
		},
		currencyOptions: {
			type: Array,
			required: true,
		},
	},
	methods: {
	},
	setup(props, { emit }) {
		const filter = ref({
			autoType: props.autoTypeFilter,
			currency: props.currencyFilter,
		})
		const resetFilter = () => {
			emit('update:currencyFilter', null)
			emit('update:autoTypeFilter', null)
			filter.value = {
				autoType: null,
				currency: null,
			}
		}

		const searchFilter = () => {
			emit('update:autoTypeFilter', filter.value.autoType)
			emit('update:currencyFilter', filter.value.currency)
		}
		return {
			resetFilter,
			searchFilter,
			filter,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
