import AutoWithdrawalService from '@/libs/auto-withdrawal-limit.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchAutoWithdrawals(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				AutoWithdrawalService.getAutoWithdrawalLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchAutoTypes(ctx) {
			return new Promise((resolve, reject) => {
				AutoWithdrawalService.getAutoTypes()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addAutoWithdrawal(ctx, AutoWithdrawalData) {
			return new Promise((resolve, reject) => {
				AutoWithdrawalService.addAutoWithdrawal(AutoWithdrawalData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		editAutoWithdrawal(ctx, AutoWithdrawalData) {
			return new Promise((resolve, reject) => {
				AutoWithdrawalService.editAutoWithdrawal(AutoWithdrawalData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		}
	}
}
